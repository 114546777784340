@import '../../main.scss';

button.ant-btn-primary {
  border-color: $color-blue;
  background: $color-blue;
}

.logo {
  padding: 22px 0  5px 14px;
  color: #fff;
  display: flex;
  align-items: center;
  line-height: 27px;
  position: relative;
  z-index: 3;
  transition: 0.5s all;
  font-size: 21px;
  font-weight: 700;
  color: black;
  transition: 0.3s all;
  border-bottom: solid 1px $header-border-color;
    svg {
      width: 107px;
      height: 41px;
    }
  &:hover {
    cursor: pointer;
  }
}

.header {
  height: 69px;
  padding: 0 30px;
  border-bottom: solid 1px $header-border-color;
  display: flex;
  align-items: center;
  //justify-content: space-between;
  justify-content: flex-end;
  background: $color-white;
  
  .username {
    display: flex;
    align-items: center;
    column-gap: 20px;
    > span {
      font-size: 18px;
    }

    &:hover {
      cursor: pointer;
    }
    
    
    h2 {
      color: #fff;
      margin: 0;
      padding-left: 20px;
    }
    // button {
    //   border: none;
    //   background: transparent;
    //   display: flex;
    //   align-items: center;
    //   column-gap:5px;
    //   padding: 0;
    //   margin-left: 10px;
    // }
  }
}

.main-title {
  flex-grow: 1;
   h2 {
    margin: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    width: max-content;
    padding: 0 10px;
   }
}

.ant-table table {
  table-layout: fixed !important;
}

.ant-layout-content {
  padding: 28px 25px 60px;
}

.menu-separator {
  margin: 30px 0;
  width: 100%;
  height: 1px;
  background-color: $header-border-color;
}

.ant-menu-item {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px !important;
  color: $color-dark-blue;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  &::after {
    content: none !important;
  }

  &:hover {
    color: $color-blue !important;
  }

  .ant-menu-item-icon {
    svg {
      fill: $color-marketplace;
    }
  }

  &:nth-last-child(2) {
    border-top: 1px solid $header-border-color;
    margin-top: 30px !important;
    padding-top: 30px !important;
    height: auto;
  }
}

.how-to-use-item {
  margin-top: 7rem !important;
  white-space: inherit !important;
  background-color: $color-icon;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  height: auto !important;
    .ant-menu-title-content {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
  .ant-menu-item {
    white-space: inherit !important;
  }
  .doc-btn {
    z-index: 1;
    width: 100%;
    background-color: transparent;
    border: 1px solid $color-white;
    border-radius: 4px;
    color: $color-white;
    font-size: 1.4rem;
  }
}

.ant-menu-item-selected.how-to-use-item {
  background-color: $color-icon !important;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.12) !important;
  .doc-btn {
    background-color: $color-white;
    color: $color-icon;
  }
}

.ant-menu-inline-collapsed {
  .how-to-use-item {
    box-shadow: none;
    background-color: transparent !important;
    height: 50px!important;
  }
}

.menuItem.ant-menu-item-selected {
  background-color: $color-white !important;
  color: $color-dark-blue;
  font-weight: 600;
  font-size: 19px;
  line-height: 28px !important;
  margin-left: -5px !important;
  a {
    color: $color-dark-blue !important;
  }
  &:hover {
    color: $color-dark-blue !important;
  }
  .ant-menu-item-icon {
    background: #fff;
    font-size: 20px;
    svg {
      fill: $color-icon;
    }
  }
}

.ant-layout-sider-trigger {
  background: transparent !important;
  color: #000 !important;
}

.ant-menu-inline-collapsed-tooltip {
  .ant-tooltip-inner {
    background-color: $color-white;
      a, span {
        color: $color-dark-blue;
        font-weight: 500;
      }
  }
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: white;
  }
}

.ant-layout-sider-collapsed {
  .ant-menu-item {
    .ant-menu-item-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      svg {
        fill: $color-marketplace;
      }
    } 
  }

  .ant-menu-item-selected {
    margin-left: 0 !important;
    .ant-menu-item-icon {
      svg {
        fill: #42ccfc;
        // transform: scale(1.4);
      }
    } 
  }

  .logo {
    span {
      width: 0;
      opacity: 0;
    }
  }
  .ant-menu {
    padding: 25px 15px !important;
  }
  .ant-menu-item-icon {
    line-height: 0 !important;
  }

  .ant-menu-item {
    width: 50px;
    height: 50px;
    padding: 0 !important;
    line-height: 0!important;
  }
  .anticon {
    vertical-align: 0 !important;
    width: 100%;
    height: 100%;
  }

}

.table-header .anticon {
  flex-shrink: 0;
  svg {
    width: 100%;
    height: 100%;
  }
}

.chart-wrapper {
  margin: 25px 0px;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  border: 1px solid #A8B8D8;
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  padding: 15px;
  &:not(:last-child) {
    margin-right: 10px;
  }
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.442553px;
    color: #252F40;
  }
}

.main-charts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1250px) {
    grid-template-columns: 1fr 1fr;
  }
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.username-mail {
  margin-right: 20px;
  fill: #67748E;
   &:hover {
     color: #42ccfc;
     fill: #42ccfc;
   }
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none; 
  -moz-user-select: none;    
  -ms-user-select: none;       
  user-select: none;    
}

@media (max-width: 1180px) {
  .ant-layout-sider {
    position: fixed;
    z-index: 10;
    height: 100%;
  }

  .site-layout {
    margin-left: 80px;
  }

  .ant-layout-sider-trigger {
    // bottom: auto;
    // top: 0;
    z-index: 12;
    border-bottom: 1px solid rgb(213, 213, 213);
  }
}
@media (max-width: 990px) {
  .ant-layout-content {
    padding: 10px 10px 60px;
  }
  
}

@media (max-width: 500px) {
  .header {
    padding: 10px;
    height: auto;
    flex-direction: column-reverse;
    align-items: flex-end;
      h2 {
        line-height: 35px;
      }
      .username {
        height: auto;
        gap: 10px;
        width: 100%;
        flex-direction: column-reverse;
      }
  }
  .ant-layout-content {
    padding-top: 20px;
  }
}

.react-tag-input {
  height: 32px !important;
}

.login-by-user-notification {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 4px;
  background-color: #fff0f0;
  border: 1px solid $color-gray;
  border-top: none;
}

.video-guide {
  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.stat-suffix {
  color: #a8a8a8;
  float: right;
  font-size: 17px;
}

.stat-suffix-up {
  color: #3f8600;
}

.stat-suffix-down {
  color: #cf1322;
}