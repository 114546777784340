$header-border-color: #EBEFF2;
$color-white: #fff;
$color-black: #000;
$color-dark: #323C47;
$color-gray: #90A0B7;
$color-dark-blue:#334D6E;
$color-light-blue:#77a4dd;
$color-blue: #109CF1;
$color-table-gray: #707683;
$color-marketplace: #C2CFE0;
$color-divider: #EBEFF2;
$color-green: #2ED47A;
$color-green-light: #DFF8E9;
$color-green-dark: #27C152;
$color-icon: #00CCFF;
$color-plan-bg: #F6F9FE;