@import '../../main.scss';

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  min-height: 45.5rem;
  background-color: $color-white;
  border-radius: 4px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.content-item {
  width: 40rem;
}


.title {
  @include f-size(h5, 500);
}

.info {
  width: 29rem;
}

.badge {
  background-color: $color-green-light;
  border-radius: 4px;
  border: 1px solid #27C152;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}