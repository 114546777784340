@mixin f-size($size: m, $weight: 400) {
  font-weight: $weight;

  @if $size == h1 {
    font-size: 44px;
    line-height: 6px;
  }
  @if $size == h2 {
    font-size: 38px;
    line-height: 52px;
  }
  @if $size == h3 {
    font-size: 24px;
    line-height: 32px;
  }
  @if $size == h4 {
    font-size: 20px;
    line-height: 28px;
  }
  @if $size == h5 {
    font-size: 18px;
    line-height: 26px;
  }
  @if $size == m {
    font-size: 16px;
    line-height: 24px;
  }
  @if $size == s {
    font-size: 14px;
    line-height: 20px;
  }
}

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
