.login_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 80px 110px;
  flex: 1;
}

.login_block {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
}

.login_block_left {
  color: #fff;
  background: #315efb;
  backdrop-filter: blur(24px);
  border-radius: 26px 0 0 26px;
  width: 60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 33px;
  font-family: Gilroy;

  h1 {
    color: #fff;
    font-size: 30px;
    padding: 0 10px;
    span {
      font-weight: 700;
      color: #1890ff;
    }
  }
  h2 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
  }
  p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 400;
    padding: 0 10px;
    font-size: 20px;
  }

  img {
    width: max-content;
    height: max-content;
    align-self: center;
    padding-top: 100px;
  }
}

.login_block_right {
  font-family: Gilroy;
  width: 40%;
  background: #fff;
  backdrop-filter: blur(24px);
  border-radius: 0 26px 26px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #343434;
    padding-bottom: 65px;
  }
  form {
    a {
      color: #6f74dd;
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
    }
    button {
      height: 40px;
    }
    .reg_btn {
      width: 100%;
      background: transparent none repeat scroll 0% 0%;
      border: 1px solid rgb(49, 94, 251);
      color: rgb(49, 94, 251);
      font-weight: 700;
    }
    input {
      border: 1px solid #d0d0d0;
      height: 40px;
      border-radius: 8px;
    }
    .or {
      display: flex;
      color: #828282;
      justify-content: center;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      position: relative;
      &::after,
      &::before {
        margin: 0 5px;
        content: " ";
        align-self: center;
        width: 25%;
        height: 1px;
        background: #e6e9fa;
      }
    }
  }
}

.slider_item {
  height: 175px;
  color: rgb(255, 255, 255);
  text-align: center;
  h2 {
    color: #fff;
    font-size: 24px;
    margin: 0;
  }
  p {
    font-size: 14px;
  }
}
