@import '../../main.scss';

.plan {
  width: 35rem;
  background-color: $color-white;
  border-radius: 4px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.popular {
  height: 4.4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: .8rem;
  border-top-left-radius: .8rem;
  background: $color-blue;
}

.content {
  padding: 3.2rem;
  width: 100%;
}

.button-wrapper {
  display: flex;
  padding: 0 3.2rem 3.2rem;
  width: 100%;
}

.button {
  min-height: 4.8rem;
  width: 100%;
}

.header {
  padding: 3.2rem 3.2rem 0 3.2rem;
}

.title {
  @include f-size(h3, 600);
  margin-bottom: 1.6rem;

}

.subtitle {
  @include f-size(h2, 600);
  margin-bottom: 0.4rem;
}

.description {
  @include f-size(m, 400);
  color: $color-dark;
  text-align: center;
}

.contacts-wrapper {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin: 4rem 0;
}