@import url(./fonts.css);
@import url(./assets/icons/flag-icon-css/flag-icon.min.css);
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  font-style: normal;
}

html:lang(en) {
  font-family: Poppins;
}
html:lang(en) body {
  font-family: Poppins;
}

html:lang(ru) {
  font-family: Montserrat;
}

html:lang(ru) body {
  font-family: Montserrat;
}