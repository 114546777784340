@import '../../main.scss';

.cards-form {
  // input {
  //   width: 100%;
  //   border: 1px solid $color-dark-blue;
  //   border-radius: 8px;
  //   padding: 5px;
  // }
  // input[name='expiry'], input[name='cvc'] {
  //   width: 100px;
  // }

  .card-input {
    background-color: white;
    border: 1px solid #bdbdbd;
    box-shadow: inset 0px 1px 2px #e5e5e5;
    height: 2.5em;
    border-radius: 0.2em;
    padding: 0 10px;
      &:focus-visible {
        outline: none;
        border-color: #40a9ff;
      }
  }

  .ant-select {
    border: 1px solid #bdbdbd;
    box-shadow: inset 0px 1px 2px #e5e5e5;
    height: 2.5em;
    border-radius: 0.2em;
    outline: none;
    &:hover {
      border: 1px solid #bdbdbd;
      box-shadow: inset 0px 1px 2px #e5e5e5;
      outline: none;
    }
    &:focus-visible {
      outline: none;
      border-color: #40a9ff;
    }
    .ant-select-selector {
      border: none;
        &:active, &:focus-visible {
          border: none;
        }
    }
    .ant-select-selection-placeholder {
      color: #7f7f7f;
    }
    .ant-select-selection-item {
      text-align: left;
    }
  }
  .ant-select-focused {
    box-shadow: inset 0px 1px 2px #e5e5e5 !important;
  }
}