@import 'mixins';
@import 'variables';
@import 'tailwind';
// @tailwind base;
// @tailwind components;

html {
  font-size: 62.5%;
}

p {
  margin: 0;
}