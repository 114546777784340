.fallback-spinner{
    position: relative;
    display: flex;
    height: 100vh;
    width: 100%;
    .loading {
      left: calc(50% - 35px);
      top: 50%;
    }
}

.mini-spinner {
  position: relative;
  width: 55px;
  height: 55px;
  margin: 0 auto;
}

.loading {
    position: absolute;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 3px solid transparent;
  
    
    .effect-1,
    .effect-2 {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 3px solid transparent;
      border-left: 3px solid #2962ff;
      border-radius: 50%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  
    .effect-1 {
      animation: rotate 1s ease infinite;
    }
    .effect-2 {
      animation: rotateOpacity 1s ease infinite 0.1s;
    }
    .effect-3 {
      width: 100%;
      height: 100%;
      border: 3px solid transparent;
      border-left: 3px solid #2962ff;
      -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
      animation: rotateOpacity 1s ease infinite 0.2s;
      border-radius: 50%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  
    .loading .effects {
      transition: all 0.3s ease;
    }
  }
  .fallback-logo {
    position: absolute;
    left: calc(50% - 45px);
    top: 40%;
  }
  
  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }
  @keyframes rotateOpacity {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 0.1;
    }
    100% {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
      opacity: 1;
    }
  }
  


.spinner2-container
{
    background-color: transparent;
    z-index: 99;
    position: fixed;
    width: 130px;
    padding: 10px;
    top: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    left: 50%;
    margin-top: -100px; 
    margin-left: -50px;
    border-radius: 0.5rem;

}

.spinner2-container p
{
    text-align: center;
    margin: 5px 0 0 0;
    font-size: 21px;
}

#upper

{
    animation: rotate;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    transform-origin: center;
}

@keyframes rotate {
    0%
    {
        transform: rotateZ(0deg);
    }
    100%
    {
        transform: rotateZ(360deg);
    }
}