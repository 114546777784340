@import '../../main.scss';

.interactive-side-bar {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.interactive-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 300vh;
}

.interactive-background {
  background: #01265E;
  opacity: 0;
  z-index: 400;
}

.interactive-content {
  display: flex;
  margin: auto 0;
  z-index: 500;
  max-width: 100%;
  position: absolute;
  top: 0;
  right: .1rem;
  transform: translate(100%);
  height: 100%;
  background-color: #fff;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
    #close {
      overflow: hidden;
      position: relative;
      border: none;
      padding: 0;
      width: 2em; height: 2em;
      border-radius: 50%;
      background: transparent;
      color: $color-blue;
      font: inherit;
      text-indent: 100%;
      cursor: pointer;
      
      &:focus {
        outline: solid 0 transparent;
        box-shadow: 0 0 0 2px $color-blue;
      }
      
      &:hover {
        background: rgba(29, 91, 161, 0.1)
      }
      
      &:before, &:after {
        position: absolute;
        top: 15%; left: calc(50% - .065em);
        width: .125em; height: 70%;
        border-radius: .125em;
        transform: rotate(45deg);
        background: currentcolor;
        content: ''
      }
      
      &:after { transform: rotate(-45deg); }
    }
}

.interactive-header {
  height: 7.2rem;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
}

.interactive-icon-close {
  cursor: pointer;
}

.interactive-side-bar-enter-done {
  & .interactive-content {
    transform: translate(0);
    transition: all 0.15s;
  }

  & .interactive-background {
    opacity: 0.15;
  }
}

.interactive-side-bar-enter-active {
  & .interactive-content {
    transform: translate(100%);
    transition: all 0.15s;
  }

  & .interactive-background {
    opacity: 0;
  }
}

.interactive-side-bar-exit-done {
  & .interactive-content {
    transform: translate(100%);
    transition: all 0.15s;
  }

  & .interactive-background {
    opacity: 0;
  }
}

.interactive-content {
  .ant-select-selector {
    .ant-select-selection-placeholder {
      text-align: start;
    }
  }
}